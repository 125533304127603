export default {
  success: true,
  logos: [
    {
      item: "Python",
      url: "https://www.vectorlogo.zone/logos/python/python-ar21.svg",
    },
    {
      item: "JavaScript",
      url: "https://www.vectorlogo.zone/logos/javascript/javascript-ar21.svg",
    },
    {
      item: "Golang",
      url: "https://www.vectorlogo.zone/logos/golang/golang-ar21.svg",
    },
    {
      item: "Bash",
      url: "https://www.vectorlogo.zone/logos/gnu_bash/gnu_bash-ar21.svg",
    },
    {
      item: "AWS",
      url: "https://www.vectorlogo.zone/logos/amazon_aws/amazon_aws-ar21.svg",
    },
    {
      item: "Azure",
      url: "https://www.vectorlogo.zone/logos/microsoft_azure/microsoft_azure-ar21.svg",
    },
    {
      item: "GCP",
      url: "https://www.vectorlogo.zone/logos/google_cloud/google_cloud-ar21.svg",
    },
    {
      item: "Terraform",
      url: "https://www.vectorlogo.zone/logos/terraformio/terraformio-ar21.svg",
    },
    {
      item: "Linux",
      url: "https://www.vectorlogo.zone/logos/linux/linux-ar21.svg",
    },
    {
      item: "FedoraLinux",
      url: "https://www.vectorlogo.zone/logos/getfedora/getfedora-ar21.svg",
    },
    {
      item: "DebianLinux",
      url: "https://www.vectorlogo.zone/logos/debian/debian-ar21.svg",
    },
    {
      item: "UbuntuLinux",
      url: "https://www.vectorlogo.zone/logos/ubuntu/ubuntu-ar21.svg",
    },
    {
      item: "Ansible",
      url: "https://www.vectorlogo.zone/logos/ansible/ansible-ar21.svg",
    },
    {
      item: "Github",
      url: "https://www.vectorlogo.zone/logos/github/github-ar21.svg",
    },
    
    {
      item: "Docker",
      url: "https://www.vectorlogo.zone/logos/docker/docker-ar21.svg",
    },
    {
      item: "Kubernetes",
      url: "https://www.vectorlogo.zone/logos/kubernetes/kubernetes-ar21.svg",
    },
  ],
  aws: [
    {
      item: "Lambda",
      url: "https://sashee.github.io/aws-svg-icons/Architecture-Service-Icons_07302021/Arch_Compute/64/Arch_AWS-Lambda_64.svg",
    },
    {
      item: "S3",
      url: "https://sashee.github.io/aws-svg-icons/Architecture-Service-Icons_07302021/Arch_Storage/64/Arch_Amazon-Simple-Storage-Service_64.svg",
    },
    {
      item: "API Gateway",
      url: "https://sashee.github.io/aws-svg-icons/Architecture-Service-Icons_07302021/Arch_App-Integration/Arch_64/Arch_Amazon-API-Gateway_64.svg",
    },
    {
      item: "Amplify",
      url: "https://sashee.github.io/aws-svg-icons/Architecture-Service-Icons_07302021/Arch_Front-End-Web-Mobile/64/Arch_AWS-Amplify_64.svg",
    },
    {
      item: "DynamoDB",
      url: "https://sashee.github.io/aws-svg-icons/Architecture-Service-Icons_07302021/Arch_Database/64/Arch_Amazon-DynamoDB_64.svg",
    },
    {
      item: "Route53",
      url: "https://sashee.github.io/aws-svg-icons/Architecture-Service-Icons_07302021/Arch_Networking-Content-Delivery/64/Arch_Amazon-Route-53_64.svg",
    },
  ],
};
